import React, { useEffect } from "react";
import { CalcInputBlock } from "./CalcInputBlock/CalcInputBlock";
import { useDispatch, useSelector } from "react-redux";
import { setCalcValue } from "../redux/app-reduser";
import { Button } from "@mui/material";
import style from "./Calc.module.css";
import { ResultBlock } from "./ResultBlock/ResultBlock";
import { WrongNoteBlock } from "./WrongNoteBlock/WrongNoteBlock";

export const AdvancedCalc = ({ metric, lang }) => {
  const maxCupDiffByInches = useSelector(
    (state) => state.app.braSizes[0].cupSize.length - 1
  );
  const maxCupDiff =
    metric === "IN"
      ? maxCupDiffByInches
      : Math.floor(maxCupDiffByInches * 2.54);
  const [params, setParams] = React.useState(null);
  const calcInputBlocks = useSelector((state) => state.app.advancedCalc);
  const dispatch = useDispatch();
  const setValue = (index) => (newValue) => {
    dispatch(
      setCalcValue({ index: index, value: newValue, calcType: "advancedCalc" })
    );
  };
  //LOOSE UNDESBUST
  useEffect(
    () => {
      if (calcInputBlocks[0].value < calcInputBlocks[1].value)
        setValue(1)(calcInputBlocks[0].value);
      if (calcInputBlocks[3].value < calcInputBlocks[0].value)
        setValue(3)(calcInputBlocks[0].value);
      if (calcInputBlocks[4].value < calcInputBlocks[0].value)
        setValue(5)(calcInputBlocks[0].value);
      if (calcInputBlocks[0].value + maxCupDiff < calcInputBlocks[4].value)
        setValue(4)(calcInputBlocks[0].value + maxCupDiff);
    },
    // eslint-disable-next-line
    [calcInputBlocks[0].value]
  );
  //SNUG UNDERBUST
  useEffect(
    () => {
      if (calcInputBlocks[1].value < calcInputBlocks[2].value)
        setValue(2)(calcInputBlocks[1].value);
      if (calcInputBlocks[1].value > calcInputBlocks[0].value) {
        setValue(0)(calcInputBlocks[1].value);
      }
    },
    // eslint-disable-next-line
    [calcInputBlocks[1].value]
  );
  //TIGHT UNDERBUST
  useEffect(
    () => {
      if (calcInputBlocks[2].value > calcInputBlocks[1].value)
        setValue(1)(calcInputBlocks[2].value);
    },
    // eslint-disable-next-line
    [calcInputBlocks[2].value]
  );
  //STANDING BUST
  useEffect(
    () => {
      if (calcInputBlocks[3].value < calcInputBlocks[0].value)
        setValue(0)(calcInputBlocks[3].value);
      if (calcInputBlocks[4].value < calcInputBlocks[3].value)
        setValue(4)(calcInputBlocks[3].value);
    },
    // eslint-disable-next-line
    [calcInputBlocks[3].value]
  );
  //LEANING BUST
  useEffect(
    () => {
      if (calcInputBlocks[4].value < calcInputBlocks[5].value)
        setValue(5)(calcInputBlocks[4].value);
      if (calcInputBlocks[4].value < calcInputBlocks[3].value)
        setValue(3)(calcInputBlocks[4].value);
      if (calcInputBlocks[0].value + maxCupDiff < calcInputBlocks[4].value)
        setValue(0)(calcInputBlocks[4].value - maxCupDiff);
    },
    // eslint-disable-next-line
    [calcInputBlocks[4].value]
  );
  //LYING BUST
  useEffect(
    () => {
      if (calcInputBlocks[5].value > calcInputBlocks[4].value)
        setValue(4)(calcInputBlocks[5].value);
      if (calcInputBlocks[5].value < calcInputBlocks[0].value)
        setValue(0)(calcInputBlocks[5].value);
    },
    // eslint-disable-next-line
    [calcInputBlocks[5].value]
  );

  const onCalculate = () => {
    const looseUnderbust = calcInputBlocks[0].value;
    const snugUnderbust = calcInputBlocks[1].value;
    const tightUnderbust = calcInputBlocks[2].value;
    const standingBust = calcInputBlocks[3].value;
    const leaningBust = calcInputBlocks[4].value;
    const lyingBust = calcInputBlocks[5].value;
    setParams({
      looseUnderbust,
      snugUnderbust,
      tightUnderbust,
      standingBust,
      leaningBust,
      lyingBust,
      metric,
    });
  };
  return (
    <div className={style.calc}>
      {calcInputBlocks.map((inputBlock, i) => {
        return (
          <div key={"advanced-" + i}>
            {i === 3 && (
              <WrongNoteBlock wrongNote={lang.wrongNote2[lang.currentLang]} />
            )}
            <CalcInputBlock
              imgSrc={inputBlock.image}
              stepName={lang[inputBlock.name][lang.currentLang]}
              value={inputBlock.value}
              setValue={setValue(i)}
              metric={metric}
              metricName={lang[`short${metric}`][lang.currentLang]}
              minValue={inputBlock.minValue[metric]}
              maxValue={inputBlock.maxValue[metric]}
              description={
                lang[inputBlock.name + "Description"][lang.currentLang]
              }
            />
          </div>
        );
      })}
      <Button
        className={style.getResult}
        variant="contained"
        color="success"
        size="large"
        onClick={onCalculate}
      >
        {lang.calculate[lang.currentLang]}
      </Button>
      <ResultBlock calcType="Advanced" params={params} lang={lang} />
    </div>
  );
};

import React from "react";
import style from "./WrongNoteBlock.module.css";

export const WrongNoteBlock = ({ wrongNote }) => {
  return (
    <div className={style.wrongNoteWrapper}>
      <div className={style.infoBlock}>
        <span>{wrongNote}</span>
      </div>
    </div>
  );
};

import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { changeCalcType, changeMetric } from "../../redux/app-reduser";
import { useDispatch } from "react-redux";
import style from "./SwitchBlock.module.css";
import { WrongNoteBlock } from "../WrongNoteBlock/WrongNoteBlock";

export const SwitchBlock = ({ metric, calcType, lang }) => {
  const dispatch = useDispatch();
  return (
    <>
      <div className={style.switchBlockWrapper}>
        {/* simple/advanced switch */}
        <ToggleButtonGroup
          className={style.calcSwitch}
          color="success"
          value={lang[calcType]["en"]}
          exclusive
          onChange={(e, newCalcType) =>
            newCalcType !== null && dispatch(changeCalcType(newCalcType))
          }
        >
          <ToggleButton value={lang.Simple["en"]}>
            {lang.Simple[lang.currentLang]}
          </ToggleButton>
          <ToggleButton value={lang.Advanced["en"]}>
            {lang.Advanced[lang.currentLang]}
          </ToggleButton>
        </ToggleButtonGroup>
        {/* cm/in switch */}
        <ToggleButtonGroup
          className={style.metricSwitch}
          color="success"
          value={lang[metric]["en"]}
          exclusive
          onChange={(e, newMetric) =>
            newMetric !== null && dispatch(changeMetric(newMetric))
          }
        >
          <ToggleButton value={lang.CM["en"]}>
            {lang.CM[lang.currentLang]}
          </ToggleButton>
          <ToggleButton value={lang.IN["en"]}>
            {lang.IN[lang.currentLang]}
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <WrongNoteBlock wrongNote={lang.wrongNote1[lang.currentLang]} />
    </>
  );
};

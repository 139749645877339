const sizeTypes = {};

//---------------------------------
//-------SIMPLE CALCULATOR--------
//---------------------------------
const simpleCalcBaseSizesInIN = (sizes, metric) => {
  const bandSizeIn = metric === "IN" ? sizes.bandSize : sizes.bandSize / 2.54;
  const bustSizeIn = metric === "IN" ? sizes.bustSize : sizes.bustSize / 2.54;
  // const underBustSizeIn =
  //   metric === "IN" ? sizes.bandSize : (sizes.bandSize + 3 * 2.54) / 2.54;
  return {
    getCupDiff: () => {
      return Math.floor(bustSizeIn - bandSizeIn);

      //return Math.floor(bustSizeIn - Math.round(bandSizeIn / 2) * 2);
    },
    getBandSize: () => {
      return Math.round(bandSizeIn / 2) * 2;
    },
  };
};
const simpleCalcBaseSizesInCM = (sizes, metric) => {
  const bandSizeCM = metric === "IN" ? sizes.bandSize * 2.54 : sizes.bandSize;
  const bustSizeCM = metric === "IN" ? sizes.bustSize * 2.54 : sizes.bustSize;

  return {
    getCupDiff: () => {
      if (bustSizeCM - bandSizeCM < 12) return 0;
      return Math.floor((bustSizeCM - bandSizeCM - 10) / 2);
    },
    getBandSize: () => {
      return Math.round(bandSizeCM / 5) * 5;
    },
  };
};

sizeTypes.UKSimple = (sizes, metric, capChart, lang) => {
  const bra = simpleCalcBaseSizesInIN(sizes, metric);

  const bandSize = bra.getBandSize();
  const capSize = capChart[bra.getCupDiff()];
  return (
    bandSize + (capSize ? capSize : lang.notExistCupSize[lang.currentLang])
  );
};
sizeTypes.USSimple = sizeTypes.UKSimple;
sizeTypes.UK4Simple = (sizes, metric, capChart, lang) => {
  const bra = simpleCalcBaseSizesInIN(sizes, metric);

  const capNumber = bra.getCupDiff() < 4 ? 0 : bra.getCupDiff() - 3;
  const capSize = capChart[capNumber];
  return (
    bra.getBandSize() +
    4 +
    (capSize ? capSize : lang.notExistCupSize[lang.currentLang])
  );
};
sizeTypes.US4Simple = sizeTypes.UK4Simple;
sizeTypes.EUSimple = (sizes, metric, capChart, lang) => {
  const bra = simpleCalcBaseSizesInCM(sizes, metric);
  const capSize = capChart[bra.getCupDiff()];
  return (
    bra.getBandSize() +
    (capSize ? capSize : lang.notExistCupSize[lang.currentLang])
  );
};
sizeTypes.FRSimple = (sizes, metric, capChart, lang) => {
  const bra = simpleCalcBaseSizesInCM(sizes, metric);
  const capSize = capChart[bra.getCupDiff()];
  return (
    +bra.getBandSize() +
    15 +
    (capSize ? capSize : lang.notExistCupSize[lang.currentLang])
  );
};
sizeTypes.AUSimple = (sizes, metric, capChart, lang) => {
  //BAND SIZE
  const braCM = simpleCalcBaseSizesInCM(sizes, metric);
  const bandSize = ((braCM.getBandSize() - 45) / 5) * 2;
  if (bandSize < 4) return lang.tooSmallBandSize[lang.currentLang];

  const capSize = capChart[braCM.getCupDiff()];

  return (
    bandSize + (capSize ? capSize : lang.notExistCupSize[lang.currentLang])
  );
};
sizeTypes.JPSimple = (sizes, metric, capChart, lang) => {
  const bandSizeCM = metric === "IN" ? sizes.bandSize * 2.54 : sizes.bandSize;
  const bustSizeCM = metric === "IN" ? sizes.bustSize * 2.54 : sizes.bustSize;

  const getCupDiff = () => {
    if (bustSizeCM - bandSizeCM < 10) return 0;
    return Math.floor((bustSizeCM - bandSizeCM - 7.5) / 2.5);
  };
  const getBandSize = () => {
    return Math.round(bandSizeCM / 5) * 5;
  };

  const capSize = capChart[getCupDiff()];
  return (
    getBandSize() + (capSize ? capSize : lang.notExistCupSize[lang.currentLang])
  );
};
sizeTypes.ITSimple = (sizes, metric, capChart, lang) => {
  //BAND SIZE
  const braCM = simpleCalcBaseSizesInCM(sizes, metric);
  const bandSize = (braCM.getBandSize() - 60) / 5;
  if (bandSize < 0) return lang.tooSmallBandSize[lang.currentLang];

  const capSize = capChart[braCM.getCupDiff()];

  return (
    bandSize + (capSize ? capSize : lang.notExistCupSize[lang.currentLang])
  );
};

//---------------------------------
//-------ADVANCED CALCULATOR--------
//---------------------------------
const advancedCalcBaseSizes = (sizes, metric, capChart, lang, startAA = 0) => {
  const looseUnderbust =
    metric === "IN" ? sizes.looseUnderbust : sizes.looseUnderbust / 2.54;
  const snugUnderbust =
    metric === "IN" ? sizes.snugUnderbust : sizes.snugUnderbust / 2.54;
  const tightUnderbust =
    metric === "IN" ? sizes.tightUnderbust : sizes.tightUnderbust / 2.54;
  const standingBust =
    metric === "IN" ? sizes.standingBust : sizes.standingBust / 2.54;
  const leaningBust =
    metric === "IN" ? sizes.leaningBust : sizes.leaningBust / 2.54;
  const lyingBust = metric === "IN" ? sizes.lyingBust : sizes.lyingBust / 2.54;

  const bandSize = getBandSize();
  const capSizeInInches =
    getBaseCapSize() + correctedBaseCapSize() < startAA
      ? 0
      : getBaseCapSize() + correctedBaseCapSize() - startAA;
  const capSize = getCapSize();

  //BAND SIZE
  function getBandSize() {
    if (
      snugUnderbust >= 38 ||
      (snugUnderbust >= 36 && snugUnderbust - tightUnderbust > 3)
    ) {
      return snugUnderbust - minBandLimit(snugUnderbust) < 1.6
        ? minBandLimit(snugUnderbust)
        : maxBandLimit(snugUnderbust);
    }

    if (snugUnderbust - tightUnderbust < 2) {
      return snugUnderbust - minBandLimit(snugUnderbust) < 0.3
        ? looseCorrection(minBandLimit(snugUnderbust), 1.5)
        : looseCorrection(maxBandLimit(snugUnderbust), 1.5);
    }

    if (snugUnderbust - tightUnderbust > 3) {
      return looseCorrection(minBandLimit(snugUnderbust));
    }

    return snugUnderbust - minBandLimit(snugUnderbust) < 0.6
      ? looseCorrection(minBandLimit(snugUnderbust))
      : looseCorrection(maxBandLimit(snugUnderbust));
  }
  function minBandLimit(size) {
    return Math.floor(size / 2) * 2;
  }
  function maxBandLimit(size) {
    return Math.ceil(size / 2) * 2;
  }
  function looseCorrection(band, min = 2.5) {
    if (Math.floor(looseUnderbust) % 2 === 0) {
      if (looseUnderbust >= band + 6 && min === 2.5) return band + 4;
      if (looseUnderbust >= band + 2) return band + 2;
      return band;
    }

    if (looseUnderbust >= band + min) return band + 2;
    return band;
  }

  //CUP SIZE
  function getBaseCapSize() {
    const bustUnderBustDiff =
      leaningBust - standingBust <= 2.5
        ? leaningBust - looseUnderbust
        : (standingBust + leaningBust + lyingBust) / 3 - looseUnderbust;
    return bustUnderBustDiff;
  }
  function correctedBaseCapSize() {
    const correcionValue = Math.round(looseUnderbust - bandSize) / 2;
    return correcionValue;
  }
  function getCapSize() {
    const localCapSize =
      capSizeInInches - Math.floor(capSizeInInches) >= 0.75 ||
      capSizeInInches - Math.floor(capSizeInInches) < 0.25
        ? capChart[Math.round(capSizeInInches)]
        : `${capChart[Math.floor(capSizeInInches)]}/${
            capChart[Math.floor(capSizeInInches) + 1]
          }`;
    return (capSizeInInches - Math.floor(capSizeInInches) < 0.75 &&
      capSizeInInches - Math.floor(capSizeInInches) >= 0.25 &&
      (!capChart[Math.floor(capSizeInInches) + 1] ||
        !capChart[Math.floor(capSizeInInches)])) ||
      !capChart[Math.round(capSizeInInches)]
      ? lang.notExistCupSize[lang.currentLang]
      : localCapSize;
  }
  return {
    getBandSize: bandSize,
    getCupSize: capSize,
    getCupDiff: capSizeInInches,
  };
};
// sizeTypes.UKAdvancedOld = (sizes, metric, capChart) => {
//   const looseUnderbust =
//     metric === "IN" ? sizes.looseUnderbust : sizes.looseUnderbust / 2.54;
//   const snugUnderbust =
//     metric === "IN" ? sizes.snugUnderbust : sizes.snugUnderbust / 2.54;
//   const tightUnderbust =
//     metric === "IN" ? sizes.tightUnderbust : sizes.tightUnderbust / 2.54;
//   const standingBust =
//     metric === "IN" ? sizes.standingBust : sizes.standingBust / 2.54;
//   const leaningBust =
//     metric === "IN" ? sizes.leaningBust : sizes.leaningBust / 2.54;
//   const lyingBust = metric === "IN" ? sizes.lyingBust : sizes.lyingBust / 2.54;

//   const bandSize = getBandSize();
//   const capSize = getCapSize();

//   function getBandSize() {
//     let bandSize = Math.round(snugUnderbust);

//     //if band even number
//     if (bandSize % 2 === 0) return bandSize;

//     //if band odd number
//     bandSize < 32 && (bandSize += 0.5);
//     bandSize > 36 && (bandSize -= 0.5);
//     snugUnderbust - tightUnderbust < 2 && (bandSize += 0.5);
//     snugUnderbust - tightUnderbust >= 4 && (bandSize -= 0.5);
//     return Math.round(bandSize / 2) * 2;
//   }
//   function getBaseCapSize() {
//     const bustUnderBustDiff =
//       leaningBust - standingBust <= 2.5
//         ? leaningBust - looseUnderbust
//         : (standingBust + leaningBust + lyingBust) / 3 - looseUnderbust;
//     return bustUnderBustDiff;
//   }
//   function correctedBaseCapSize() {
//     const correcionValue = Math.round(looseUnderbust - bandSize) / 2;
//     return correcionValue;
//   }
//   function getCapSize() {
//     const capSizeInInches = getBaseCapSize() + correctedBaseCapSize();
//     const localCapSize =
//       Math.round(capSizeInInches) === Math.floor(capSizeInInches)
//         ? capChart[Math.floor(capSizeInInches)]
//         : `${capChart[Math.floor(capSizeInInches)]}/${
//             capChart[Math.round(capSizeInInches)]
//           }`;

//     return (Math.round(capSizeInInches) !== Math.floor(capSizeInInches) &&
//       !capChart[Math.round(capSizeInInches)]) ||
//       !capChart[Math.floor(capSizeInInches)]
//       ? '"нет такого размера чашки"'
//       : localCapSize;
//   }
//   return bandSize + capSize;
// };
sizeTypes.UKAdvanced = (sizes, metric, capChart, lang) => {
  const braSizes = advancedCalcBaseSizes(sizes, metric, capChart, lang);
  return braSizes.getBandSize + braSizes.getCupSize;
};
sizeTypes.USAdvanced = (sizes, metric, capChart, lang) => {
  const braSizes = advancedCalcBaseSizes(sizes, metric, capChart, lang);
  return braSizes.getBandSize + braSizes.getCupSize;
};
sizeTypes.UK4Advanced = (sizes, metric, capChart, lang) => {
  const braSizes = advancedCalcBaseSizes(sizes, metric, capChart, lang, 3);
  return braSizes.getBandSize + 4 + braSizes.getCupSize;
};
sizeTypes.US4Advanced = (sizes, metric, capChart, lang) => {
  const braSizes = advancedCalcBaseSizes(sizes, metric, capChart, lang, 3);
  return braSizes.getBandSize + 4 + braSizes.getCupSize;
};

const getCupSizeInCm = (cupDiffInCm, capChart) => {
  const minCupDiff = (cupDiff) => Math.floor((cupDiff - 10) / 2) * 2;
  const maxCupDiff = (cupDiff) => Math.ceil((cupDiff - 10) / 2) * 2;
  const getCupSize = (cupDiff) => {
    const correctCupDiff = cupDiff / 2 < 0 ? 0 : cupDiff / 2;
    return capChart[correctCupDiff] ? capChart[correctCupDiff] : "";
  };
  const minCupSize = getCupSize(minCupDiff(cupDiffInCm));
  const maxCupSize = getCupSize(maxCupDiff(cupDiffInCm));
  if (Math.round(cupDiffInCm - 10) % 2 === 0 || maxCupDiff(cupDiffInCm) < 0.25)
    return Math.round(cupDiffInCm - 10) === minCupDiff(cupDiffInCm)
      ? minCupSize
      : maxCupSize;
  return minCupSize && maxCupSize ? minCupSize + "/" + maxCupSize : "";
};
sizeTypes.EUAdvanced = (sizes, metric, capChart, lang) => {
  const braSizes = advancedCalcBaseSizes(sizes, metric, capChart, lang);

  const bandSize = (braSizes.getBandSize - 24) * 2.5 + 60;
  const cupSize = getCupSizeInCm(braSizes.getCupDiff * 2.54, capChart);

  return (
    bandSize + (cupSize ? cupSize : lang.notExistCupSize[lang.currentLang])
  );
};
sizeTypes.FRAdvanced = (sizes, metric, capChart, lang) => {
  const braSizes = advancedCalcBaseSizes(sizes, metric, capChart, lang);

  const bandSize = (braSizes.getBandSize - 24) * 2.5 + 75;
  const cupSize = getCupSizeInCm(braSizes.getCupDiff * 2.54, capChart);

  return (
    bandSize + (cupSize ? cupSize : lang.notExistCupSize[lang.currentLang])
  );
};
sizeTypes.AUAdvanced = (sizes, metric, capChart, lang) => {
  const braSizes = advancedCalcBaseSizes(sizes, metric, capChart, lang);

  const bandSize = (braSizes.getBandSize - 24) * 2.5 + 6;
  const cupSize = getCupSizeInCm(braSizes.getCupDiff * 2.54, capChart);

  return (
    bandSize + (cupSize ? cupSize : lang.notExistCupSize[lang.currentLang])
  );
};
sizeTypes.ITAdvanced = (sizes, metric, capChart, lang) => {
  const braSizes = advancedCalcBaseSizes(sizes, metric, capChart, lang);

  const bandSize = (braSizes.getBandSize - 24) * 2.5;
  const cupSize = getCupSizeInCm(braSizes.getCupDiff * 2.54, capChart);

  return (
    bandSize + (cupSize ? cupSize : lang.notExistCupSize[lang.currentLang])
  );
};
sizeTypes.JPAdvanced = (sizes, metric, capChart, lang) => {
  const braSizes = advancedCalcBaseSizes(sizes, metric, capChart, lang, 3);

  const bandSize = (braSizes.getBandSize - 24) * 2.5 + 60;
  // const cupSize = getCupSizeInCm(braSizes.getCupDiff * 2.54, capChart);

  return bandSize + braSizes.getCupSize;
};

export const calculateBraSize = (
  sizeType,
  calcType,
  metric,
  params,
  cupSizes,
  lang
) => {
  if (!sizeTypes[sizeType + calcType]) return "**";
  return sizeTypes[sizeType + calcType](params, metric, cupSizes, lang);
};

import React, { useEffect, useState } from "react";
import style from "./CalcInputBlock.module.css";
import { Button } from "@mui/material";
import { InputAdornment, Slider, TextField } from "@mui/material";

export const CalcInputBlock = ({
  imgSrc,
  stepName,
  value,
  setValue,
  metric,
  metricName,
  minValue,
  maxValue,
  description,
}) => {
  let [localValue, setLocalValue] = useState(value);
  const onBlur = () => {
    value === maxValue && setLocalValue(maxValue);
    value === minValue && setLocalValue(minValue);
    setValue(+localValue);
  };
  const changeTextFieldValue = (newValue) => {
    newValue !== "" ? setLocalValue(+newValue) : setLocalValue("");
  };

  useEffect(() => {
    value < minValue && setValue(minValue);
    value > maxValue && setValue(maxValue);
  });

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <div className={style.step}>
      <div className={style.stepAction}>
        <div className={style.img}>
          <img src={imgSrc} alt={stepName} />
        </div>
        <div className={style.inputBlock}>
          <div className={style.inputTextBlock}>
            <div className={style.decremendBlock}>
              <Button
                variant="contained"
                size="small"
                onClick={(e) => setValue(+value - 1)}
                disabled={+value <= minValue ? true : false}
              >
                -1
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={(e) => setValue(+value - 0.1)}
                disabled={+value <= minValue ? true : false}
              >
                -0.1
              </Button>
            </div>
            <TextField
              className={style.input}
              label={stepName}
              variant="outlined"
              type="number"
              value={localValue}
              onBlur={onBlur}
              onChange={(e) => changeTextFieldValue(e.currentTarget.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{metricName}</InputAdornment>
                ),
              }}
            />
            <div className={style.decremendBlock}>
              <Button
                variant="contained"
                size="small"
                onClick={(e) => setValue(+value + 1)}
                disabled={+value >= maxValue ? true : false}
              >
                +1
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={(e) => setValue(+value + 0.1)}
                disabled={+value >= maxValue ? true : false}
              >
                +0.1
              </Button>
            </div>
          </div>

          <Slider
            className={style.slider}
            value={typeof value === "number" ? value : 0}
            aria-labelledby="input-slider"
            min={minValue}
            max={maxValue}
            onChange={(e, newValue) => setValue(newValue)}
          />
        </div>
      </div>
      <div className={style.stepInfo}>{description}</div>
    </div>
  );
};

import "./App.css";
import React, { useEffect } from "react";
import { SimpleCalc } from "./component/SimpleCalc";
import { SwitchBlock } from "./component/SwitchBlock/SwitchBlock";
import { useDispatch, useSelector } from "react-redux";
import { AdvancedCalc } from "./component/AdvancedCalc";
import { useMatch } from "react-router-dom";
import { setLanguage } from "./redux/app-reduser";

function App() {
  const match = useMatch("/:language");
  // const metric = useSelector((store) => store.app.metric);
  const calcType = useSelector((store) => store.app.calcType);
  const lang = useSelector((store) => store.app.lang);
  const currentLang = useSelector((store) => store.app.currentLang);
  const dispatch = useDispatch();

  useEffect(
    () => {
      console.log(match);
      if (match?.params.language === "ru" || match?.params.language === "fr") {
        dispatch(setLanguage(match.params.language));
      } else {
        dispatch(setLanguage("en"));
      }
    },
    // eslint-disable-next-line
    []
  );

  return (
    <div className="App">
      <SwitchBlock
        metric={lang.currentMetric[lang.currentLang]}
        calcType={calcType}
        lang={lang}
        currentLang={currentLang}
      />
      {calcType === "Simple" ? (
        <SimpleCalc metric={lang.currentMetric[lang.currentLang]} lang={lang} />
      ) : (
        <AdvancedCalc
          metric={lang.currentMetric[lang.currentLang]}
          lang={lang}
        />
      )}
    </div>
  );
}

export default App;

import React, { useEffect } from "react";
import { CalcInputBlock } from "./CalcInputBlock/CalcInputBlock";
import { useDispatch, useSelector } from "react-redux";
import { setCalcValue } from "../redux/app-reduser";
import { Button } from "@mui/material";
import style from "./Calc.module.css";
import { ResultBlock } from "./ResultBlock/ResultBlock";
export const SimpleCalc = ({ metric, lang }) => {
  const [params, setParams] = React.useState(null);
  const maxCupDiffByInches = useSelector(
    (state) => state.app.braSizes[0].cupSize.length - 1
  );
  const maxCupDiff =
    metric === "IN"
      ? maxCupDiffByInches
      : Math.floor(maxCupDiffByInches * 2.54);
  const calcInputBlocks = useSelector((state) => state.app.simpleCalc);
  const bustSize = useSelector((state) => state.app.simpleCalc[0].value);
  const bandSize = useSelector((state) => state.app.simpleCalc[1].value);
  const dispatch = useDispatch();
  const setValue = (index) => (newValue) => {
    dispatch(
      setCalcValue({ index: index, value: newValue, calcType: "simpleCalc" })
    );
  };
  //BUST SIZE
  useEffect(
    () => {
      if (bustSize > bandSize + maxCupDiff) setValue(1)(bustSize - maxCupDiff);
      if (bustSize < bandSize) setValue(1)(bustSize);
    },
    // eslint-disable-next-line
    [bustSize]
  );
  //BAND SIZE
  useEffect(
    () => {
      if (bustSize > bandSize + maxCupDiff) setValue(0)(bandSize + maxCupDiff);
      if (bandSize > bustSize) setValue(0)(bandSize);
    },
    // eslint-disable-next-line
    [bandSize]
  );

  const onCalculate = () => {
    setParams({ bustSize, bandSize, metric });
  };
  return (
    <div className={style.calc}>
      {calcInputBlocks.map((inputBlock, i) => {
        return (
          <CalcInputBlock
            key={"simple-calc-" + i}
            imgSrc={inputBlock.image}
            stepName={lang[inputBlock.name][lang.currentLang]}
            value={inputBlock.value}
            setValue={setValue(i)}
            metric={metric}
            metricName={lang[`short${metric}`][lang.currentLang]}
            minValue={inputBlock.minValue[metric]}
            maxValue={inputBlock.maxValue[metric]}
            description={
              lang[inputBlock.name + "Description"][lang.currentLang]
            }
          />
        );
      })}
      <Button
        className={style.getResult}
        variant="contained"
        color="success"
        size="large"
        onClick={onCalculate}
      >
        {lang.calculate[lang.currentLang]}
      </Button>
      <ResultBlock calcType="Simple" params={params} lang={lang} />
    </div>
  );
};

import { createSlice } from "@reduxjs/toolkit";
import bustSize from "../assets/images/bust2.png";
import bandSize from "../assets/images/band2.png";
import learningSize from "../assets/images/learning.png";
import lyingSize from "../assets/images/lying.png";
import { calculateBraSize } from "../helpers/calculator";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    calcType: "Simple",
    simpleCalc: [
      {
        name: "bustSize",
        value: 0,
        minValue: {
          IN: 24,
          CM: 62,
        },
        maxValue: {
          IN: 92,
          CM: 234,
        },
        image: bustSize,
      },
      {
        name: "bandSize",
        value: 0,
        minValue: {
          IN: 24,
          CM: 62,
        },
        maxValue: {
          IN: 66,
          CM: 168,
        },
        image: bandSize,
      },
    ],
    advancedCalc: [
      {
        name: "looseUnderbust",
        value: 0,
        minValue: {
          IN: 24,
          CM: 62,
        },
        maxValue: {
          IN: 66,
          CM: 168,
        },
        image: bandSize,
      },
      {
        name: "snugUnderbust",
        value: 0,
        minValue: {
          IN: 24,
          CM: 62,
        },
        maxValue: {
          IN: 66,
          CM: 168,
        },
        image: bandSize,
      },
      {
        name: "tightUnderbust",
        value: 0,
        minValue: {
          IN: 24,
          CM: 62,
        },
        maxValue: {
          IN: 66,
          CM: 168,
        },
        image: bandSize,
      },
      {
        name: "standingBust",
        value: 0,
        minValue: {
          IN: 24,
          CM: 62,
        },
        maxValue: {
          IN: 86,
          CM: 234,
        },
        image: bustSize,
      },
      {
        name: "leaningBust",
        value: 0,
        minValue: {
          IN: 24,
          CM: 62,
        },
        maxValue: {
          IN: 86,
          CM: 234,
        },
        image: learningSize,
      },
      {
        name: "lyingBust",
        value: 0,
        minValue: {
          IN: 24,
          CM: 62,
        },
        maxValue: {
          IN: 86,
          CM: 234,
        },
        image: lyingSize,
      },
    ],
    braSizes: [
      {
        name: "UK",
        metric: "IN",
        value: { Simple: "-", Advanced: "-" },
        cupSize: [
          "AA",
          "A",
          "B",
          "C",
          "D",
          "DD",
          "E",
          "F",
          "FF",
          "G",
          "GG",
          "H",
          "HH",
          "J",
          "JJ",
          "K",
          "KK",
          "L",
          "LL",
          "M",
          "MM",
          "N",
          "NN",
          "P",
          "PP",
          "Q",
          "QQ",
        ],
      },
      {
        name: "US",
        metric: "IN",
        value: { Simple: "-", Advanced: "-" },
        cupSize: [
          "AA",
          "A",
          "B",
          "C",
          "D",
          "DD ̸E",
          "DDD ̸F",
          "DDDD ̸G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
        ],
      },
      {
        name: "UK4",
        metric: "IN",
        value: { Simple: "-", Advanced: "-" },
        cupSize: [
          "AA",
          "A",
          "B",
          "C",
          "D",
          "DD",
          "E",
          "F",
          "FF",
          "G",
          "GG",
          "H",
          "HH",
          "J",
          "JJ",
          "K",
          "KK",
          "L",
          "LL",
          "M",
          "MM",
          "N",
          "NN",
          "P",
          "PP",
          "Q",
          "QQ",
        ],
      },
      {
        name: "US4",
        metric: "IN",
        value: { Simple: "-", Advanced: "-" },
        cupSize: [
          "AA",
          "A",
          "B",
          "C",
          "D",
          "DD ̸E",
          "DDD ̸F",
          "DDDD ̸G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
        ],
      },
      {
        name: "EU",
        metric: "CM",
        value: { Simple: "-", Advanced: "-" },
        cupSize: [
          "AA",
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
        ],
      },
      {
        name: "FR",
        metric: "CM",
        value: { Simple: "-", Advanced: "-" },
        cupSize: [
          "AA",
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
        ],
      },
      {
        name: "AU",
        metric: "CM",
        value: { Simple: "-", Advanced: "-" },
        cupSize: [
          "AA",
          "A",
          "B",
          "C",
          "D",
          "DD",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
        ],
      },
      {
        name: "JP",
        metric: "CM",
        value: { Simple: "-", Advanced: "-" },
        cupSize: [
          "AA",
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
        ],
      },
      {
        name: "IT",
        matric: "CM",
        value: { Simple: "-", Advanced: "-" },
        cupSize: [
          "AA",
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
        ],
      },
    ],
    lang: {
      currentLang: "en",
      currentMetric: {
        en: "IN",
        ru: "CM",
        fr: "CM",
      },
      rightCourseResultSizes: {
        en: [],
        ru: ["EU", "UK", "FR", "IT", "US", "JP", "AU", "UK4", "US4"],
        fr: ["FR", "EU", "IT", "UK", "US", "JP", "AU", "UK4", "US4"],
      },
      CM: {
        en: "CM",
        ru: "СМ",
        fr: "СМ",
      },
      IN: {
        en: "IN",
        ru: "Дюймы",
        fr: "Pouces",
      },
      shortCM: {
        en: "CM",
        ru: "СМ",
        fr: "CM",
      },
      shortIN: {
        en: "IN",
        ru: "''",
        fr: "''",
      },
      Simple: {
        en: "Simple",
        ru: "Простой",
        fr: "Simple",
      },
      Advanced: {
        en: "Advanced",
        ru: "Продвинутый",
        fr: "Avancé",
      },
      bustSize: {
        en: "Standing Bust",
        ru: "Грудь",
        fr: "Sein",
      },
      bustSizeDescription: {
        en: "Standing or sitting fully erect. The measurement must be loose and around the widest part of your bust.",
        ru: "Встаньте или сядьте прямо. Измерительная лента должна лишь слегка давить на кожу, а измерение должно производится вокруг самой широкой части бюста.",
        fr: "Tenez-vous debout ou asseyez-vous droit. Le ruban à mesurer ne doit être que légèrement pressé contre la peau et la mesure doit être prise autour de la partie la plus large du sein.",
      },
      bandSize: {
        en: "Snug Underbust",
        ru: "Под грудью",
        fr: "Sous le sein",
      },
      bandSizeDescription: {
        en: "The measurement tape should not press too hard - make it as comfy as you would like the bra to feel.",
        ru: "Измерительная лента не должна давить слишком сильно — натяните ее при измерении так, как вы хотите, чтобы ощущался бюстгальтер.",
        fr: "Le ruban à mesurer ne doit pas être trop serré - tirez-le fermement pendant que vous le mesurez comme vous voulez que le soutien-gorge se sente.",
      },
      looseUnderbust: {
        en: "Loose Underbust",
        ru: "Без нажатия",
        fr: "Sans pression",
      },
      looseUnderbustDescription: {
        en: "Barely touch the area under your breasts with the tape - it should not press at all",
        ru: "Едва коснитесь лентой при измерении окружности под грудью – лента вообще не должна давить",
        fr: "Touchez à peine le ruban lorsque vous mesurez la tour sous le sein - le ruban ne doit pas appuyer du tout",
      },
      snugUnderbust: {
        en: "Snug Underbust",
        ru: "Комфортное",
        fr: "Confort",
      },
      snugUnderbustDescription: {
        en: "The measurement tape should not press too hard - make it as comfy as you would like the bra to feel.",
        ru: "Измерительная лента не должна давить слишком сильно — натяните ее при измерении так, как вы хотите, чтобы ощущался бюстгальтер.",
        fr: "Le ruban à mesurer ne doit pas être trop serré - tirez-le fermement pendant que vous le mesurez comme vous voulez que le soutien-gorge se sente.",
      },
      tightUnderbust: {
        en: "Tight Underbust",
        ru: "Сильное",
        fr: "Fort",
      },
      tightUnderbustDescription: {
        en: "Tighten the measurement tape under your bust as much as possible",
        ru: "Максимально затяните измерительную ленту под грудью.",
        fr: "Serrez le mètre ruban au maximum sous le sein",
      },
      standingBust: {
        en: "Standing Bust",
        ru: "Стоя",
        fr: "Debout",
      },
      standingBustDescription: {
        en: "Standing or sitting fully erect.",
        ru: "Встаньте или сядьте прямо.",
        fr: "Tenez-vous debout ou asseyez-vous droit.",
      },
      leaningBust: {
        en: "Leaning Bust",
        ru: "Наклонившись",
        fr: "En position penchée",
      },
      leaningBustDescription: {
        en: "Leaning over 90 degrees.",
        ru: "Наклонитесь на 90 градусов.",
        fr: "Penchez-vous à 90 degrés.",
      },
      lyingBust: {
        en: "Lying Bust",
        ru: "Лежа на спине",
        fr: "En position dorsal",
      },
      lyingBustDescription: {
        en: "Lying on your back",
        ru: "Лягте на спину",
        fr: "Allongez-vous sur le dos",
      },
      calculate: {
        en: "Calculate",
        ru: "Посчитать",
        fr: "Calculer",
      },
      resultTitle: {
        en: "Bra size:",
        ru: "Ваш размер:",
        fr: "Votre taille",
      },
      tooSmallBandSize: {
        en: " ??",
        ru: " ??",
        fr: " ??",
      },
      notExistCupSize: {
        en: "**",
        ru: "**",
        fr: "**",
      },
      UK: {
        en: "UK+0",
        ru: "UK+0",
        fr: "UK+0",
      },
      US: {
        en: "US/CA+0",
        ru: "US/CA+0",
        fr: "US/CA+0",
      },
      UK4: {
        en: "UK+4",
        ru: "UK+4",
        fr: "UK+4",
      },
      US4: {
        en: "US/CA+4",
        ru: "US/CA+4",
        fr: "US/CA+4",
      },
      FR: {
        en: "FR/BE/ES",
        ru: "FR/BE/ES",
        fr: "FR/BE/ES",
      },
      EU: {
        en: "EU (EN 13402)",
        ru: "Российский(RU)/Европейски(EU)",
        fr: "EU",
      },
      AU: {
        en: "AU/NZ",
        ru: "AU/NZ",
        fr: "AU/NZ",
      },
      JP: {
        en: "JP/KO",
        ru: "JP/KO",
        fr: "JP/KO",
      },
      IT: {
        en: "IT",
        ru: "IT",
        fr: "IT",
      },
      wrongNote1: {
        en: "You should take all measurements on the naked bust.",
        ru: "Все измерения производятся на голой груди.",
        fr: "Toutes les mesures sont prises sur le sein nu",
      },
      wrongNote2: {
        en: "All bust measurements below must be loose and around the widest part of your bust.",
        ru: "Все измерения ниже должны происходить с легким надвливанием и вокруг самой широкой части бюста",
        fr: "Toutes les mesures ci-dessous doivent être prises avec une légère pression et autour de la partie la plus large du sein.",
      },
      resultNote: {
        en: "That cup size calculated isn't currently listed.",
        ru: "Рассчитанный размер чашечки слишком большой. Его нет в таблице размеров данной страны",
        fr: "La taille des bonnets calculée est trop grande. Ce n'est pas dans le tableau des tailles de ce pays",
      },
    },
  },
  reducers: {
    getBraSizes: (state, action) => {
      state.braSizes[action.payload.index].value[state.calcType] =
        calculateBraSize(
          action.payload.braSizeType,
          state.calcType,
          state.lang.currentMetric[state.lang.currentLang],
          action.payload.params,
          state.braSizes[action.payload.index]["cupSize"],
          state.lang
        );
    },
    setCalcValue: (state, action) => {
      let newValue = action.payload.value;
      const maxValue =
        state[action.payload.calcType][action.payload.index].maxValue[
          state.lang.currentMetric[state.lang.currentLang]
        ];
      const minValue =
        state[action.payload.calcType][action.payload.index].minValue[
          state.lang.currentMetric[state.lang.currentLang]
        ];

      newValue % 1 !== 0 && (newValue = Math.round(newValue * 10) / 10);

      +newValue < minValue && (newValue = minValue);
      newValue > maxValue && (newValue = maxValue);

      state[action.payload.calcType][action.payload.index].value = newValue;
    },
    setMaxValue: (state, action) => {
      state[action.payload.calcType][action.payload.index].maxValue[
        state.lang.currentMetric[state.lang.currentLang]
      ] = action.payload.maxValue;
    },
    setMinValue: (state, action) => {
      state[action.payload.calcType][action.payload.index].minValue[
        state.lang.currentMetric[state.lang.currentLang]
      ] = action.payload.minValue;
    },
    changeMetric: (state, action) => {
      state.lang.currentMetric[state.lang.currentLang] = action.payload;
    },
    changeCalcType: (state, action) => {
      state.calcType = action.payload;
    },
    setLanguage: (state, action) => {
      state.lang.currentLang = action.payload;
      const rightCourse =
        state.lang.rightCourseResultSizes[state.lang.currentLang];
      state.braSizes.forEach(
        (item) =>
          (item.id =
            rightCourse.indexOf(item.name) === -1
              ? 100
              : rightCourse.indexOf(item.name))
      );
      state.braSizes.sort((a, b) => {
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
        return 0;
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getBraSizes,
  setCalcValue,
  setAdvancedCalcValue,
  setMinValue,
  setMaxValue,
  changeMetric,
  changeCalcType,
  setLanguage,
} = appSlice.actions;

export default appSlice.reducer;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getBraSizes } from "../../redux/app-reduser";
import style from "./ResultBlock.module.css";

export const ResultBlock = ({ params, lang, calcType }) => {
  const braSizes = useSelector((state) => state.app.braSizes);
  const dispatch = useDispatch();

  return (
    <div className={style.result}>
      <h2>{lang.resultTitle[lang.currentLang]}</h2>
      <div className={style.resultItems}>
        {braSizes.map((size, index) => {
          params &&
            dispatch(
              getBraSizes({
                index: index,
                braSizeType: size.name,
                params: params,
              })
            );
          return (
            <BraSizeBlock
              key={size.name + "_key"}
              name={lang[size.name][lang.currentLang]}
              size={size.value[calcType]}
            />
          );
        })}
      </div>

      {/* if results have ** */}
      {braSizes.some((size) => size.value[calcType].indexOf("**") > -1) && (
        <div className={style.note}>
          ** - {lang.resultNote[lang.currentLang]}
        </div>
      )}
    </div>
  );
};

const BraSizeBlock = ({ name, size }) => {
  const Plus4 = name.indexOf("+4") > 1 ? true : false;
  const Plus0 = name.indexOf("+0") > 1 ? true : false;
  return (
    <div className={style.resultItem}>
      <strong>
        {name.replace("+4", "").replace("+0", "")}{" "}
        {Plus4 ? <sup>+4</sup> : Plus0 ? <sup>+0</sup> : ""}:{" "}
      </strong>
      <span>{size}</span>
    </div>
  );
};
